<template>
  <div>
    <plans-table />
  </div>
</template>
<script>
import plansTable from "./index.vue";

export default {
  components: {
    plansTable,
  },
};
</script>
